import * as actionType from "../types";

export const updateBand = (bandData) => {
  const { id, basePrice, travelPrice, slug, pieces } = bandData;

  return (dispatch) => {
    dispatch({
      type: actionType.BAND_UPDATE,
      payload: {
        id: id,
        slug: slug,
        basePrice: basePrice,
        travelPrice: travelPrice,
        total: basePrice + travelPrice,
        pieces: pieces
      },
    });
  };
};