import axios from "axios";
import CryptoJS from "crypto-js";

import { sendSlackMessage } from "./slack";
const getServiceName = (port) => {
  switch (port) {
    case 4000:
      return "bridgelogin";
    case 4001:
      return "bridgeuser";
    case 4002:
      return "bridgeclient";
    case 4003:
      return "bridgemail";
    case 4004:
      return "maintenance";
    case 4006:
      return "bridgesms";
    case 4007:
      return "user";
    case 4008:
      return "cms";
    case 4009:
      return "starlightband";
    case 4010:
      return "band";
    case 4011:
      return "events";
    case 4012:
      return "bridgeevent";
    default:
      break;
  }
};

export const AxiosInstance = (port, contentType, version = "v1",contractId = "") => {
  const service = getServiceName(port);
  const Axios = axios.create({
    baseURL:
    process.env.REACT_APP_NODE_ENV === "production" ||
    process.env.REACT_APP_NODE_ENV === "staging"
    ? port === 4003
    ? `${process.env.SOCKET_URL}/api/v1`
    : `${process.env.API_BASE_URL}/${service}/api/${version}/`
    : `${process.env.API_BASE_URL}:${port}/api/${version}/`,
  });
  //Is not server side rendering ?
  if (typeof window !== "undefined") {
    Axios.defaults.headers.common["Authorization"] = DecryptObjectData(localStorage.getItem(
      "token"
    ))
    ? `Bearer ${DecryptObjectData(localStorage.getItem("token"))}`
    : null;
  }
  Axios.defaults.headers.common["Content-Type"] = contentType;
  Axios.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  Axios.interceptors.response.use(
    async (response) => {
      const statusCode = response?.data ? response?.data?.status : 404;
      if(statusCode >= 400){
        const user_data = typeof window !== "undefined" ?  DecryptObjectData(localStorage.getItem("data")) : null;
        let errmsg =''
        if(response?.data?.msg && response?.data?.msg.trim() !== ""){
          let errs = response?.data?.err ? response?.data?.err : response?.data?.validationErrors
          errs?.map((msg, index) => (
            errmsg += '\n'+capitalizeAfterSplit(msg?.parameter)+" -- "+msg?.message+'\n'
          ))
          if(response?.data?.msg1) errmsg += '\n'+capitalizeAfterSplit(response?.data?.msg1)+'\n'
          if(contractId){
            let apiURL = ["production", "staging"].includes(process.env.REACT_APP_NODE_ENV)
            ? `${process.env.API_BASE_URL}/contract/${contractId}`
            : `${process.env.API_BASE_URL}:3000/contract/${contractId}`
            errmsg += `<${apiURL}|Click here> to view the contract!`
          }
        }
      // if (err) {

        const name = response.data?.clientName ? `${response.data?.clientName?.firstName ?? ""} ${response.data?.clientName?.lastName ?? ""}`.trim() : (response?.data?.user ? response?.data?.user?.firstName+" "+response?.data?.user?.lastName : user_data?.firstName+" "+user_data?.lastName)
        const errorInfo = {
          statusCode,
          message: response?.data?.msg,
          text: errmsg,
          clientName: name,
          api: response?.config?.baseURL.slice(0, -1)+response?.config?.url
        };
        // Send error info to Slack
        if(["production", "staging"].includes(process.env.REACT_APP_NODE_ENV)){
          const slackMessage = {
            text: `*Error Alert*\nStatus Code: ${statusCode}\nClient name: ${name}\nMessage: ${response?.data?.msg}\nAPI: ${response?.config?.baseURL.slice(0, -1)+response?.config?.url}\nError: ${errmsg}`,
          };
          try{

            await sendSlackMessage(slackMessage);
          }catch(err){
            console.error("Error sending Slack message:", err);
          }
        }
      // }
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return Axios;
};
function capitalizeAfterSplit(input) {
  return input.split('.').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ');
}
export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("data");
  // return setTimeout(() => {
  //   window.location.href = "/";
  // }, 400);
}

export const EncryptObjectData = (data) => {
  try {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.LOCAL_STORAGE_KEY ?? "s@tA!r^l*i&g_h+t"
      );
    return encryptedData;
  } catch(error){
    console.log("Error while encrypting data: ", error);
  }
};

export const DecryptObjectData = (encryptedData, config={}) => {
  const {ignoreLogout=false} = config;
  try {
    if(!encryptedData) return null
    const bytesData = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.LOCAL_STORAGE_KEY ?? "s@tA!r^l*i&g_h+t"
    );
    const originalData = bytesData ? JSON.parse(bytesData.toString(CryptoJS.enc.Utf8)) : "";
    return originalData;
  } catch(e) {
    console.log("Error while decrypting data: ", e);
    console.log({encryptedData})
    if(!ignoreLogout) logout()
  }
};

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const addLogsToMaintenanceService = ({title, errorMsg, isSuccess}) => {
  AxiosInstance(4004, "application/json")
    .post("add/logs", {title, errorMsg, isSuccess})
    .then((resp) => {
      const response = resp?.data
      if(response?.status === 200)  console.log("Logged successfully")
      else {
        console.error(response.msg ?? "Something went wrong while adding logs", { title, errorMsg})
      }
    }).catch(e => {
      console.error(e.message ?? e)
    })
}