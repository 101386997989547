import { timeDiffCalc } from "../../helper/service";
import * as actionType from "../types";

let timer = null;

// INITIALIZES CLOCK
export const startClock = (expireTime) => (dispatch) => {
  clearInterval(timer);
  timer = setInterval(async () => {
    const timeDiffrence = await timeDiffCalc(new Date(), new Date(expireTime));
    dispatch({
      type: actionType.TICK,
      payload: {
        hours: timeDiffrence.totalHours,
        minutes: timeDiffrence.minutes,
      },
    });
  }, 1000);
};

export const updateContract = (contractId, expireTime) => {
  return (dispatch) => {
    dispatch({
      type: actionType.CONTRACT_UPDATE,
      payload: {
        id: contractId,
        endDate: expireTime,
      },
    });
  };
};
