export async function sendSlackMessage(message) {
    const webhookUrl = process.env.SLACK_WEBHOOK_URL;

    if (!webhookUrl) {
        console.error("SLACK_WEBHOOK_URL is missing");
        return;
    }
    try {
        const response = await fetch(webhookUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(message),
        });

        if (!response.ok) {
            throw new Error(`Slack API Error: ${response.statusText}`);
        }

        console.log("Slack message sent successfully!");
    } catch (error) {
        console.error("Error sending Slack message:", error);
    }
}