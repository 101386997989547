import { combineReducers } from "redux";

import achReducer from "./achReducer";
import bandReducer from "./bandReducer";
import ContractReducer from "./contractReducer";
import LoginReducer from "./loginReducer";
import searchReducer from "./searchReducer";

// COMBINED REDUCERS
const reducers = {
  contract: ContractReducer,
  search: searchReducer,
  auth: LoginReducer,
  band: bandReducer,
  ach: achReducer,
};

export default combineReducers(reducers);
