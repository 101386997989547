import * as actionType from "../types";

// INITIAL STATE
const initialState = {
  id: "",
  slug: "",
  basePrice: 0,
  travelPrice: 0,
  total: 0,
  pieces: 0
};

export const updateBand = (state, payload) => {
  return {
    ...state,
    id: payload.id,
    slug: payload.slug,
    basePrice: payload.basePrice,
    travelPrice: payload.travelPrice,
    total: payload.total,
    pieces: payload.pieces
  };
};


const BandReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionType.BAND_UPDATE:
      return updateBand(state, payload);
    default:
      return state;
  }
};

export default BandReducer;
