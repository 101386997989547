import * as actionType from "../types";

export const updateAchAccessToken = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionType.ACH_SET_ACCESS,
      payload: data,
    });
  };
};

export const updateAchLinkToken = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionType.ACH_SET_LINK,
      payload: data,
    });
  };
};
