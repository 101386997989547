import { useEffect, useState } from "react";

import { Modal } from "antd";

import reuseableStyles from "../../styles/ReuseableComponents.module.css"


const AutoRefreshAlertComponent = ({ visible, handleVisible, ...props }) => {
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    if (visible) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            window.location.reload(true);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [visible]);

  return (
    <>
      <Modal
        title="Alert: Update Incoming"
        visible={visible}
        centered={true}
        okText="Refresh Now"
        cancelText="Close"
        closable={false}
        wrapClassName={reuseableStyles["auto-refresh-modal-wrap"]}
        className={reuseableStyles["auto-refresh-modal"]}
        onOk={() => window.location.reload(true)}
        onCancel={() => handleVisible(false)}
      >
        We are rolling out a critical update. Please save your changes within the next {countdown} seconds.
      </Modal>
    </>
  );
}

export default AutoRefreshAlertComponent