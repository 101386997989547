// REDUX ACTION TYPES
export const TICK = "TICK";
export const CONTRACT_UPDATE = "CONTRACT_UPDATE";

export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAILURE = "SEARCH_FAILURE";
export const SEARCH_CLEAR = "SEARCH_CLEAR";
export const SEARCH_UPDATE = "SEARCH_UPDATE";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const RETRIEVE_SEARCH = "RETRIEVE_SEARCH";
export const TRAVEL_PRICES_DISTANCE = "TRAVEL_PRICES_DISTANCE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const ON_LOGOUT = "ON_LOGOUT";
export const ON_GOOGLE_SIGNIN = "ON_GOOGLE_SIGNIN";
export const ON_OTP_RECEIVE = "ON_OTP_RECEIVE";

export const RETRIEVE_AUTH = "RETRIEVE_AUTH";

export const AUTH_LOADED = "AUTH_LOADED";

export const BAND_UPDATE = "BAND_UPDATE";

export const ACH_SET_ACCESS = "ACH_SET_ACCESS";
export const ACH_SET_LINK = "ACH_SET_LINK";