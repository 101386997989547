import * as actionType from "../types";

// INITIAL STATE
const initialState = {
  linkSuccess: false,
  isItemAccess: true,
  linkToken: "", // Don't set to null or error message will show up briefly when site loads
  accessToken: null,
  itemId: null,
  isError: false,
  backend: true,
  products: ["transactions"],
  linkTokenError: {
    error_type: "",
    error_code: "",
    error_message: "",
  },
};

export const setAccessToken = (state, payload) => {
  return {
    ...state,
    itemId: payload.itemId,
    accessToken: payload.accessToken,
    isItemAccess: payload.isItemAccess,
  }
}

export const setLinkToken = (state, payload) => {
  return {
    ...state,
    linkToken: payload.linkToken
  }
}

const BandReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionType.ACH_SET_ACCESS:
      return setAccessToken(state, payload);
    case actionType.ACH_SET_LINK:
      return setLinkToken(state, payload);
    default:
      return { ...state };
  }
};

export default BandReducer;
