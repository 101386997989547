import { AxiosInstance } from "../../utilities";
import * as actionType from "../types";

export const searchRequest = () => {
  return {
    type: actionType.SEARCH_REQUEST,
  };
};

export const updateSearch = (searchData) => {
  return {
    type: actionType.SEARCH_UPDATE,
    searchData: searchData,
  };
};

export const setTravelPriceAndDistance = (travelPrices, distance) => {
  return {
    type: actionType.TRAVEL_PRICES_DISTANCE,
    travelPrices,
    distance
  };
};

export const searchSuccess = (searchData, resData) => {
  return {
    type: actionType.SEARCH_SUCCESS,
    search_result: resData.data,
    pager: resData.pager,
    searchData: searchData,
    creditCardCharge: resData.creditCardCharge
  };
};

export const searchFailure = (searchData, error) => {
  return {
    type: actionType.SEARCH_FAILURE,
    searchData: searchData,
    search_result: [],
    error: error,
  };
};

export const removeSearchError = () => {
  return {
    type: actionType.REMOVE_ERROR,
  };
};

export const clearSearch = () => {
  return {
    type: actionType.SEARCH_CLEAR,
  };
};

export const clientSideRenderSearch = () => {
  return {
    type: actionType.RETRIEVE_SEARCH,
  };
};

/**
 * @author Benakesha
 * @param {SEARCH DATA} searchData
 * @returns Dispatch reducer
 * TO DO SEARCH ACTIONS
 */
export const SearchAction = (searchData) => {
  return (dispatch) => {
    dispatch(searchRequest());
    console.log(searchData);
    dispatch(updateSearch(searchData));
    // delete searchData.event_place;
    AxiosInstance(4009, "application/json")
      .post("/search", searchData)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          dispatch(searchSuccess(searchData, response));
        } else if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          dispatch(searchFailure(searchData, response.msg));
        }
      })
      .catch((err) => {
        console.error(err)
        dispatch(searchFailure(searchData, "Internal Server Error"));
      });
  };
};
