/**
 * @author Benakesha
 * @param {old state} oldObject
 * @param {new state} newObject
 * @returns
 * TO RETRUN THE UPDATED STATES
 */

export const UpdateObjects = (oldObject, newObject) => {
  return {
    ...oldObject,
    ...newObject,
  };
};
