import { DecryptObjectData } from "../../utilities";
import * as actionType from "../types";
import { UpdateObjects } from "../UpdateObject";

export const initialState = {
  token: null,
  userData: null,
  error: null,
  loading: false,
  isUser: false,
  otp: null,
  otpId: null,
  isReady: false, //Indicates app loaded in browser and userData fetched from localStorage
};

const loginRequest = (state, action) => {
  return UpdateObjects(state, {
    error: null,
    loading: true,
  });
};

const loginSuccess = (state, action) => {
  return UpdateObjects(state, {
    error: null,
    loading: false,
    token: action.token,
    userData: action.data,
    isUser: action.isUser,
    otp: null,
    otpId: null,
  });
};

const loginFailure = (state, action) => {
  return UpdateObjects(state, {
    error: action.error,
    loading: false,
  });
};

const removeErrors = (state, action) => {
  return UpdateObjects(state, { error: null, loading: false });
};

const onLogout = (state, action) => {
  return UpdateObjects(state, {
    error: null,
    loading: false,
    token: null,
    userData: null,
    isUser: false,
    otp: null,
    otpId: null,
  });
};

const onGoogleSignin = (state, action) => {
  return UpdateObjects(state, {
    error: null,
    loading: false,
    token: action.token,
    userData: action.data,
    isUser: action.isUser,
  });
};

const onOtpReceive = (state, action) => {
  return UpdateObjects(state, {
    otp: action.otp,
    otpId: action.otpId,
    token: null,
    userData: null,
  });
};

const getAuthStateFromStorage = (state) => {
  const token = DecryptObjectData(localStorage.getItem("token")) || initialState.token;
  let userData = DecryptObjectData(localStorage.getItem("data"));
  userData =
    typeof userData === "string" && userData !== "null"
      ? JSON.parse(userData)
      : userData || initialState.userData;
  return UpdateObjects(state, { userData, token });
};

const setIsAuthReady = (state, action) => {
  return UpdateObjects(state, { isReady: action.isReady });
};

/**
 * @author Benakesha
 * @param {initial states} state
 * @param {actions} action
 * @returns
 * TO CHANGE THE INITIAL STATES BASED ON ACTIONS
 */
const LoginReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case actionType.LOGIN_REQUEST:
      return loginRequest(state, action);
    case actionType.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionType.LOGIN_FAILURE:
      return loginFailure(state, action);
    case actionType.REMOVE_ERROR:
      return removeErrors(state, action);
    case actionType.ON_LOGOUT:
      return onLogout(state, action);
    case actionType.ON_GOOGLE_SIGNIN:
      return onGoogleSignin(state, action);
    case actionType.ON_OTP_RECEIVE:
      return onOtpReceive(state, action);
    case actionType.RETRIEVE_AUTH:
      return getAuthStateFromStorage(state, action);
    case actionType.AUTH_LOADED:
      return setIsAuthReady(state, action);
    default:
      return state;
  }
};

export default LoginReducer;
