import * as actionType from "../types";
import { UpdateObjects } from "../UpdateObject";

const initialState = {
  pager: null,
  searchData: null,
  search_result: null,
  error: null,
  loading: false,
  travelPrices: null,
  distance: 0
};

const searchRequest = (state, action) => {
  return UpdateObjects(state, {
    error: null,
    loading: true,
  });
};

const searchSuccess = (state, action) => {
  return UpdateObjects(state, {
    error: null,
    loading: false,
    // searchData:action.searchData,
    search_result: action.search_result,
    pager: action.pager,
    creditCardCharge: action.creditCardCharge
  });
};

const searchFailure = (state, action) => {
  return UpdateObjects(state, {
    error: action.error,
    searchData: action.searchData,
    search_result: [],
    loading: false,
  });
};

const removeErrors = (state, action) => {
  return UpdateObjects(state, { error: null, loading: false });
};

const update_search = (state, action) => {
  // localStorage.setItem("searchData", JSON.stringify(action.searchData));
  const updatedSearchData = UpdateObjects(state, {
    searchData: action.searchData,
  });
  localStorage.setItem("searchData", JSON.stringify(updatedSearchData));
  return updatedSearchData;
};

/**
 *
 * @param {*} state  Search redux state data
 * @param {*} action
 * @description      Retrieve the application search data saved in browser localStorage
 *                   , load to redux state.
 */
const retrieveSearchStateFromStorage = (
  state = initialState,
  action = null
) => {
  let searchData = localStorage.getItem("searchData");

  searchData =
    typeof searchData === "string" &&
    searchData !== "null" &&
    searchData !== null
      ? JSON.parse(searchData)
      : initialState.searchData;

  return UpdateObjects(state, { ...searchData });
};

/**
 * @author Benakesha
 * @param {initial states} state
 * @param {actions} action
 * @returns
 * TO CHANGE THE INITIAL STATES BASED ON ACTIONS
 */
const SearchReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case actionType.SEARCH_REQUEST:
      return searchRequest(state, action);
    case actionType.SEARCH_SUCCESS:
      return searchSuccess(state, action);
    case actionType.SEARCH_FAILURE:
      return searchFailure(state, action);
    case actionType.REMOVE_ERROR:
      return removeErrors(state, action);
    case actionType.SEARCH_UPDATE:
      return update_search(state, action);
    case actionType.RETRIEVE_SEARCH:
      return retrieveSearchStateFromStorage(state, action);
    case actionType.TRAVEL_PRICES_DISTANCE:
      return {
        ...state,
        travelPrices: action.travelPrices,
        distance: action.distance
      }
    default:
      return state;
  }
};

export default SearchReducer;
