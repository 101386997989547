import { message } from "antd";

import { AxiosInstance, EncryptObjectData } from "../../utilities";
import * as actionType from "../types";

// For server side rendering, using ON_LOGOUT type to
// to update auth store to the initialState.
export const serverRenderAuth = () => (dispatch) =>
  dispatch({
    type: actionType.ON_LOGOUT,
    // payload: {}
  });

export const clientSideRenderAuth = () => (dispatch) => {
  return dispatch({ type: actionType.RETRIEVE_AUTH });
};

export const isAuthReady = (isReady) => (dispatch) => {
  return dispatch({ type: actionType.AUTH_LOADED, isReady });
};

export const loginRequest = () => {
  return {
    type: actionType.LOGIN_REQUEST,
  };
};

export const loginSuccess = (userToken, userData, isUser) => {
  return {
    type: actionType.LOGIN_SUCCESS,
    token: userToken,
    data: userData,
    isUser: isUser,
  };
};

export const loginFailure = (error) => {
  console.log('errr',error)
  return {
    type: actionType.LOGIN_FAILURE,
    error: error,
  };
};

export const removeError = () => {
  return {
    type: actionType.REMOVE_ERROR,
  };
};

export const onLogout = () => {
  return {
    type: actionType.ON_LOGOUT,
  };
};

export const updateStateOnGoogleSignin = (userToken, userData) => {
  return {
    type: actionType.ON_GOOGLE_SIGNIN,
    token: userToken,
    data: userData,
    isUser: true,
  };
};

export const updateOtp = ({ id, otp }) => {
  return {
    type: actionType.ON_OTP_RECEIVE,
    otp: otp,
    otpId: id,
  };
};

/**
 * @author Benakesha
 * @param {FORM DATA} formData
 * @param {"/login","/admin/login"} url
 * @returns Dispatch reducer
 * TO DO LOGIN ACTIONS FOR USER ,FACILITATORS,AND ADMIN
 */
export const LoginAction = (formData, url, redirectUrl) => {
  return (dispatch) => {
    dispatch(loginRequest());
    AxiosInstance(4007, "application/json")
      .post(url, formData)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          let istoken = response.token ? true : false;
          if (istoken === true) {
            localStorage.setItem("token", EncryptObjectData(response.token));
            localStorage.setItem("data", EncryptObjectData({...(response?.userData), role: response?.userData?.role?.trim()?.toLowerCase()?.replaceAll(" ","_")}));
            !!response.deviceToken && localStorage.setItem("deviceToken", response.deviceToken);
            if (redirectUrl !== undefined && redirectUrl !== "/") {
              window.location.href = redirectUrl;
            }
            dispatch(
              loginSuccess(
                response.token,
                {...(response?.userData), role: response?.userData?.role?.trim()?.toLowerCase()?.replaceAll(" ","_")},
                url === "/client/login" ? true : false
              )
            );
          } else if (url === "/client/login") {
            dispatch(updateOtp(resp.data));
          }
          message.success(response.msg, 3);
        } else if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          dispatch(loginFailure(response.msg));
          message.error(response.msg, 3);
        }
      })
      .catch((err) => {
        console.log('err',err)
        dispatch(loginFailure("Internal Server Error"));
      });
  };
};
