/* eslint-disable @next/next/no-sync-scripts */
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "../styles/globals.css";
import "../public/assets/fonts/stylesheet.css";
import "react-toastify/dist/ReactToastify.min.css";
import "../styles/vendors/Animate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-phone-input-2/lib/style.css";
import "react-pro-sidebar/dist/css/styles.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { polyfill } from "interweave-ssr";
import Head from "next/head";
import Script from "next/script";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import AutoRefreshAlertComponent from "../helper/ReusableComponents/AutoRefreshAlertComponent";
import { initializeAppState } from "../store/actions";
import { useStore } from "../store/store";
import { addLogsToMaintenanceService } from "../utilities";

// Apply polyfill for interweave SSR
polyfill();

const publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
// Optionally load Stripe immediately for non-staging/production environments
let stripePromise = null;
if (!["staging", "production"].includes(process.env.REACT_APP_NODE_ENV)) {
  stripePromise = loadStripe(publishableKey);
}

function MyApp({ Component, pageProps }) {
  const store = useStore(pageProps.initialReduxState);
  const [stripePromiseData, setStripePromiseData] = useState(null);
  const [currentVersion, setCurrentVersion] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    // Initialize app state from Redux store
    store.dispatch(initializeAppState());
  }, [store]);

  useEffect(() => {
    // Load Stripe asynchronously only once, and log the connection
    loadStripe(publishableKey)
      .then((loadedStripe) => {
        setStripePromiseData(loadedStripe);
        addLogsToMaintenanceService({
          title: "Stripe connected successfully",
          isSuccess: true,
        });
      })
      .catch((stripeError) => {
        console.error({ stripeError });
        addLogsToMaintenanceService({
          title: "Stripe",
          errorMsg: stripeError?.message ?? stripeError,
        });
      });
  }, []);

  useEffect(() => {
    // Function to fetch the current version from version.json
    const fetchVersion = async () => {
      try {
        const res = await fetch("/version.json");
        const data = await res.json();
        return data.version;
      } catch (error) {
        console.error("Error fetching version:", error);
        return null;
      }
    };

    // Set initial version
    fetchVersion().then((version) => {
      if (version) {
        setCurrentVersion(version);
      }
    });

    // Check for version updates every 60 seconds
    const interval = setInterval(async () => {
      const newVersion = await fetchVersion();
      console.log({ newVersion, currentVersion });
      if (newVersion && newVersion !== currentVersion) {
        setIsModalVisible(true);
        // Reload the page after 30 seconds if a new version is detected
        setTimeout(() => window.location.reload(true), 30000);
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [currentVersion]);

  const handleAutoRefreshAlertModal = (visible) => setIsModalVisible(visible);

  return (
    <Provider store={store}>
      <Elements stripe={stripePromise ?? stripePromiseData}>
        <Head>
          <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, viewport-fit=cover"
          />
          {/* Preload logos based on viewport width */}
          <link
            rel="preload"
            as="image"
            href="/assets/logos/BlueStarlightMusic-Logo-CMYK-Horizontal-highres-transparent_reduced.webp"
            media="max-width: 767px)"
          />
          <link
            rel="preload"
            as="image"
            href="/assets/logos/BlueStarlightMusic-Logo-CMYK-hires-transparent.webp"
            media="(min-width: 768px)"
          />
        </Head>
        {/* Defer loading of non-critical config.js */}
        <Script src="/config.js" strategy="lazyOnload" />
        <Component {...pageProps} />
        <AutoRefreshAlertComponent
          visible={isModalVisible}
          handleVisible={handleAutoRefreshAlertModal}
        />
      </Elements>
      <ToastContainer position="bottom-right" hideProgressBar autoClose={4000} />
    </Provider>
  );
}

export default MyApp;
