import * as actionType from "../types";

// INITIAL TIMER STATE
const initialTimerState = {
  id: "",
  endDate: "",
  hours: 0,
  minutes: 0,
};

export const updateContract = (state, payload) => {
  return {
    ...state,
    id: payload.id,
    endDate: payload.endDate,
  };
};

// Contract REDUCER
const ContractReducer = (state = initialTimerState, { type, payload }) => {
  switch (type) {
    case actionType.TICK:
      return {
        ...state,
        hours: payload.hours,
        minutes: payload.minutes,
      };
    case actionType.CONTRACT_UPDATE:
      return updateContract(state, payload);
    default:
      return state;
  }
};

export default ContractReducer;
