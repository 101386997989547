/* eslint-disable array-callback-return */
import Dinero from "dinero.js";
import moment from "moment";

import { AxiosInstance } from "../utilities";
import { calculateDineroPercentage } from "./money";

/**
 * @author Benakesha
 * @param {array of object} data
 * @returns updated objects
 * TO UPDATE THE ARRAY OF OBJECT
 */
module.exports.modifyArray = (data) => {
  let newArray = [];
  data.forEach((item) => {
    let obj = {};
    obj._id = item._id;
    obj.name = item.name;
    obj.value = item._id;
    newArray.push(obj);
  });
  return newArray;
};

export const isWholeNumber = (number) => {
  return (number - Math.floor(number)) === 0
}


module.exports.clearLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("data");
};

module.exports.checkIsObjectEmpty = (Data) => {
  return Object.values(Data).every((x) => x === null || x === "");
};

module.exports.getGooglePlaceObjectValues = (place) => {
  var placeObj = {
    address: place?.formatted_address,
    lat: place?.geometry?.location.lat(),
    lng: place?.geometry?.location.lng(),
  };
  return placeObj;
};
module.exports.getCity = (addressArray) => {
  let city = "";
  for (let i = 0; i < addressArray.length; i++) {
    if (
      addressArray[i].types[0] &&
      "administrative_area_level_2" === addressArray[i].types[0]
    ) {
      city = addressArray[i].short_name;
      return city;
    }
  }
};
module.exports.getZipcode = (addressArray) => {
  let zipcode = "";
  for (let i = 0; i < addressArray.length; i++) {
    if (
      addressArray[i].types[0] &&
      "postal_code" === addressArray[i].types[0]
    ) {
      zipcode = addressArray[i].short_name;
      return zipcode;
    }
  }
};

module.exports.getArea = (addressArray) => {
  let area = "";
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0]) {
      for (let j = 0; j < addressArray[i].types.length; j++) {
        if (
          "sublocality_level_1" === addressArray[i].types[j] ||
          "locality" === addressArray[i].types[j]
        ) {
          area = addressArray[i].short_name;
          return area;
        }
      }
    }
  }
};

module.exports.getState = (addressArray) => {
  let state = "";
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_1" === addressArray[i].types[0]
      ) {
        state = addressArray[i].short_name;
        return state;
      }
    }
  }
};

// Function to generate random number
module.exports.randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

// Convert camelcase to word
module.exports.camelcaseToWord = (text) => {
  var result = text.replace(/([A-Z])/g, " $1");
  var finalResult =
    result.charAt(0).toUpperCase() + result.slice(1).replace(/-/g, " ");
  return finalResult;
};

// Updated value of object by key
module.exports.updateObject = (array, newData) => {
  newData.map(({ id, key, value }) => {
    const objIdx = array.findIndex((obj) => obj.id === id);
    array[objIdx][key] = value;
  });
  return array;
};

// Get total from array of objects with quantity
module.exports.getTotalQuantityPrice = (
  array,
  multiplyingProp,
  keyProp,
  margin
) => {
  let marginRate = 1 - margin / 100;
  let total = array.reduce((prev, cur) => {
    if (cur[multiplyingProp] > 0) {
      return Math.round(prev + (cur[keyProp] * cur[multiplyingProp]) / marginRate);
    }
    return prev;
  }, 0);
  return Math.round(total);
};

// Calculate margin
const applyMargin = (price, margin) => {
  return price / (1 - margin / 100);
};

export const getMarginAndRateDifference = (price, margin) => {
  const mRate = price / (1 - margin / 100);
  return mRate - price;
};

module.exports.applyMargin = applyMargin;

//capitalize only the first letter of the string.
module.exports.capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatToCurrency = (amount, options={isRoundOff: true}) => {
  if(options.isRoundOff){
    return Math.round(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    .split(".")[0];    
  }

  return isWholeNumber(amount) ? amount : Number(amount).toFixed(2)
    // .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    // .split(".")[0];
};

module.exports.calculateDaysBetweenDates = (date1, date2) => {
  var oneDay = 24 * 60 * 60 * 1000;
  var date1InMillis = date1.getTime();
  var date2InMillis = date2.getTime();
  var days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay);
  return days;
};

module.exports.pluralize = (text, num) => (num > 1 ? text + "s" : text);

export const timeDiffCalc = async (dateFuture, dateNow) => {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  const totalHours = days > 0 ? days * 24 + hours : hours;

  let difference = {
    days: days > 0 ? days : 0,
    hours: hours,
    totalHours: totalHours,
    minutes: minutes,
  };

  return difference;
};

export const _fetchFooterCmsData = (data = { showAll: false }) => {
  return new Promise((resolve, reject) => {
    AxiosInstance(4008, "application/json")
      .post("/footerLinks", data)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          const response_Data = response.data;
          resolve(response_Data);
        } else if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          resolve(null);
        }
      })
      .catch((err) => {
        console.error("fetch_footer_cms_data  services err---", err);
        // reject(err);
        resolve(null);
      });
  });
};

export const _fetchCmsPageData = (params) => {
  return new Promise((resolve, reject) => {
    AxiosInstance(4008, "application/json")
      .post("/pages", params)
      .then((res) => {
        const response = res && res.data;
        if (response.status == "200") {
          resolve(response.data);
        } else {
          resolve(null);
        }
      })
      .catch((er) => {
        console.log("error on fetch cms page data");
        resolve(null);
      });
  });
};

/**
 * Generate a psuedo random string
 * See https://stackoverflow.com/a/27747377/64904
 * @param {integer} len  length of the returned string
 */
export const generateId = (len = 40) => {
  // dec2hex :: Integer -> String i.e. 0-255 -> '00'-'ff'
  const arr = new Uint8Array((len || 40) / 2);
  function dec2hex(dec) {
    return `0${dec.toString(16)}`.substr(-2);
  }
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
};

export const isValidURL = (string) => /^[a-z][a-z0-9+.-]*:/.test(string);

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const titleCase = (str) => {
  return str
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

//Get performers count
export const getTotalPerformers = (arr) => {
  if (arr === undefined) {
    return 0;
  } else {
    const count = arr
      .filter((item) => item.noTravel === false)
      .reduce((prev, cur) => {
        if (cur.default > 0) {
          return prev + cur.default;
        }
        return prev;
      }, 0);
    return count;
  }
};

//Get band members count
export const getTotalMembers = (arr, haveSoundCompany) => {
  if (arr === undefined) {
    return 0;
  } else {
    let count = arr.reduce((prev, cur) => {
      if (cur.default > 0) {
        return prev + cur.default;
      }
      return prev;
    }, 0);
    if (haveSoundCompany) {
      count += 1;
    }
    return count;
  }
};

export const getCoordinatesBoundingBoxOnRange = (lat, lng, radius = 80467) => {
  if ([lat, lng, radius].every(i => i !== undefined)) {
    // The 69 and 53 are fixed constants that are used to calculate latitude and longitude degrees from miles.
    // 69 miles ≈ 1 degree of latitude everywhere on earth. This is because latitude lines are horizontal and the distance between each degree of latitude is approximately 69 miles.
    // 53 miles ≈ 1 degree of longitude at the equator. Longitude lines converge at the poles, so the distance between degrees varies based on your latitude. At the equator, it's around 53 miles per degree.

    const latDelta = radius/69;
    const lngDelta = radius/53;
    // debugger
    const bounds = {
      north: /* Math.floor */(lat + latDelta),
      south: /* Math.floor */(lat - latDelta),  
      east: /* Math.floor */(lng + lngDelta),
      west: /* Math.floor */(lng - lngDelta),
    }
    return bounds
  }
  console.warn("No lat/lng/radius info passed to find boundary")
  return null
}

export const transformGoogleAddressComponents = (addressComponents) => {
  const result = { City: [], State: [], Country: [] }

  if (Array.isArray(addressComponents)) {

    addressComponents.forEach?.(component => {
      if (component.types.includes('locality')) {
        result.City.push(component.long_name, component.short_name)
      } else if (!result.City.length && component.types.includes('administrative_area_level_2')) {
        result.City.push(component.long_name, component.short_name)
      } else if (component.types.includes('administrative_area_level_1')) {
        result.State.push(component.long_name, component.short_name)
      } else if (component.types.includes('country')) {
        result.Country.push(component.long_name, component.short_name)
      }
    })
    return result
  }
  return null
}


export const calculateCoordinatesDistance = function (destLat, destLong, orgLat, orgLong) {
  // Convert coordinates to radians
  const destLatRad = toRadians(destLat);
  const destLongRad = toRadians(destLong);
  const orgLatRad = toRadians(orgLat);
  const orgLongRad = toRadians(orgLong);

  // Earth's radius in kilometers
  const radius = 6371;

  // Haversine formula
  const dlat = orgLatRad - destLatRad;
  const dlon = orgLongRad - destLongRad;
  const a =
    Math.sin(dlat / 2) * Math.sin(dlat / 2) +
    Math.cos(destLatRad) * Math.cos(orgLatRad) *
    Math.sin(dlon / 2) * Math.sin(dlon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = radius * c;

  return distance;
}

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}


export const getTravelPricingItemsForPosition = (team, performerSlot, travelInfo, marginInfo) => {
  const isSER = team.positionType === 6;
//if string position and is upSell ed then consider as travel performer
  const willFlyWithBand = (team.positionType === 2) ? !!performerSlot?.upSell?.margin : !team.noTravel && !isSER; //[isSER] check can be removed since its based on noTravel.
  const margin = Number(performerSlot?.upSell?.margin ?? marginInfo.margin);

  const { bandMeal = 0, flightFare = 0, hotelFare = 0, } = travelInfo;
  const { perDiem = 0, baggageFee = 0, distance = 0, serWareHouseDistance = 0, truckRatePerMile = 0 } = travelInfo;
  // const { travelBusPlusCost = 0, travelBusCost = 0 } = travelInfo;



  const pTravelList = {};
  const wDistance = isFinite(serWareHouseDistance) ? serWareHouseDistance : distance;
  const serTravelRate = wDistance > 90 ? truckRatePerMile * wDistance : 0;

  //margin for flight and hotel rates have to include in band pricing instead of travel
  //in order to show the price being balanced on different sections
  // as per initial main site frontend band config.

  if (distance > 360) {
    if (willFlyWithBand) {
      pTravelList.bandMeal = applyMargin(bandMeal, margin);
      pTravelList.baggageFee = baggageFee;
      pTravelList.flightFare = flightFare;
      pTravelList.hotelFare = (hotelFare * 2); //2 day hotel charge for distance > 360
      pTravelList.perDiem = perDiem;
    } else if (isSER) {//with SER there will be two people always multiple 2 for each item;
      pTravelList.bandMeal = applyMargin((bandMeal * 2), margin);
      pTravelList.hotelFare = (hotelFare * 2) * 2;
      pTravelList.serTravelRate = applyMargin((serTravelRate), margin);
      pTravelList.perDiem = (perDiem * 2);
    } else {
      //not fly with band
      pTravelList.bandMeal = applyMargin(bandMeal, margin);
      pTravelList.hotelFare = (hotelFare * 2);
      pTravelList.perDiem = perDiem;

    }
  } else if (distance <= 360 && distance > 300) {
    if (willFlyWithBand) {
      pTravelList.hotelFare = (hotelFare * 2);
      // pTravelList.travelBusPlusCost = applyMargin(travelBusPlusCost, margin);
    } else if (isSER) {
      pTravelList.hotelFare = (hotelFare * 2 * 2); // 
      // pTravelList.travelBusPlusCost = applyMargin(travelBusPlusCost, margin);
      pTravelList.serTravelRate = applyMargin((serTravelRate), margin);
    } else {
      //not fly with band
      pTravelList.hotelFare = (hotelFare * 2);
    }
  } else if (distance <= 300 && distance > 175) {
    if (isSER) {
      // pTravelList.travelBusCost = applyMargin(travelBusCost, margin);
      pTravelList.serTravelRate = applyMargin((serTravelRate), margin);
    }
  } else if (distance <= 175 && distance >= 90) {
    if (isSER) {
      pTravelList.serTravelRate = applyMargin((serTravelRate), margin);
    }
  }

  return pTravelList;
}

const calculateSegmentDuration = (segment) => {
  const st = moment(segment.startTime, "hh:mm a")
  const et = moment(segment.endTime, "hh:mm a")
  let newDiff = et.diff(st, "minutes");
  if (st > et) {
    newDiff += 24 * 60;
  }
  newDiff /= 60;
  return newDiff;
}


/**
 * 
 * @param {*} overtimeSegment updated overtime segment config
 * @param {*} previousOvertimeSegmentConfig previous overtime segment config for comparison.  if present
 *                                          increase/decreases changed rate to segments customRate value
 * @param {*} bandRate computed band rate
 * @returns 
 */
const getCustomRateForOvertimeSegment = (overtimeSegment, previousOvertimeSegmentConfig = null, bandRate) => {
  previousOvertimeSegmentConfig = null; //temporary setting here.
  let /* previousDuration = null, */ updatedDuration = null, changedDurationDiff = 0;
  updatedDuration = calculateSegmentDuration(overtimeSegment);
  if (previousOvertimeSegmentConfig) {
    // previousDuration = calculateSegmentDuration(previousOvertimeSegmentConfig);
    //previousDuration and updatedDuration are same, its about bandRate ref value.
    // changedDurationDiff = previousDuration - updatedDuration;
    // const previousCustomRate = previousOvertimeSegmentConfig.customRate;
    // changedDurationDiff = updatedDuration;
    // const units = Math.ceil(changedDurationDiff / 30);

    // // const overtimePercent = units * 12.5;
    // // const oldCustomRate = previousOvertimeSegmentConfig.customRate || 0;
    // // const newCustomRate = 
    // // let rate = Dinero({ amount: Math.round(bandRate * 100 || 0) });
    // // rate = calculateDineroPercentage(rate, overtimePercent);
    // return rate.toRoundedUnit(0);
  } else {
    //: every 30 minutes will be charged with 12.5%
    //convert the difference to minutes
    if ([1, true].includes(overtimeSegment.fullBand)) {
      const defDuration = overtimeSegment.defaultDuration ?? overtimeSegment.configuredDuration ?? overtimeSegment.duration
      updatedDuration = updatedDuration > defDuration ? updatedDuration - defDuration : 0
      // updatedDuration - overtimeSegment.duration
    }
    changedDurationDiff = updatedDuration;

    changedDurationDiff = changedDurationDiff * 60;
    const units = Math.ceil(changedDurationDiff / 30);
    let rate = Dinero({ amount: Math.round(bandRate * 100 || 0) });
    rate = calculateDineroPercentage(rate, 12.5).toRoundedUnit(0);
    const finalRate = units * rate;

    return finalRate;
  }

};

const checkIsASpecialSegment = (segmentId, eventSegments, nonEventSegments = null) => {
  let fullBandSegment = null, overtimeSegment = null;
  try {
    if (segmentId && eventSegments) {
      const segments = nonEventSegments || eventSegments
      fullBandSegment = eventSegments.find(s => s.fullBand);
      overtimeSegment = null;
      if (fullBandSegment) {
        overtimeSegment = segments.find(s => (/\bovertime\b/i).test(s.name) && (s._id === segmentId || s.segmentId === segmentId))
      }
    }
  } catch (e) {
    return { fullBandSegment, overtimeSegment };
  }
  return { fullBandSegment, overtimeSegment };
}

export const SegmentOperations = {
  calculateSegmentDuration,
  getCustomRateForOvertimeSegment,
  checkIsASpecialSegment
}
