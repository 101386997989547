import { clientSideRenderAuth, clientSideRenderSearch, isAuthReady } from ".";
export { startClock, updateContract } from "./contract_action";

export {
  SearchAction,
  removeSearchError,
  clearSearch,
  clientSideRenderSearch,
} from "./searchAction";

export {
  serverRenderAuth,
  clientSideRenderAuth,
  LoginAction,
  removeError,
  updateStateOnGoogleSignin,
  updateOtp,
  loginSuccess,
  onLogout,
  isAuthReady,
} from "./loginAction";

export { updateBand } from "./bandAction";

export { updateAchAccessToken, updateAchLinkToken } from "./achAction";

export function initializeAppState() {
  return (dispatch) => {
    dispatch(clientSideRenderAuth());
    dispatch(clientSideRenderSearch());
    dispatch(isAuthReady(true));
  };
}
