import Dinero from "dinero.js";

Dinero.defaultCurrency = 'USD'
/**
 * Dinero package doesn't allow calculating percentage of amount > 100.
 * This function splits percent into groups of 100s and total them
 * and return a new Dinero object.
 * @param {*} dineroObj 
 * @param {*} percent 
 * @returns 
 */
export const calculateDineroPercentage = (dineroObj, percent) => {
    const quot = Math.trunc(percent / 100);
    const remainder = percent % 100;
    let newDineroObj = Dinero({ amount: 0 });
    for (let i = 0; i < quot; i++) {
        newDineroObj = newDineroObj.add(dineroObj.percentage(100));
    }

    if (remainder > 0) {
        newDineroObj = newDineroObj.add(dineroObj.percentage(remainder))
    }

    return newDineroObj;

}

const helperFn = {
    calculateDineroPercentage
}

export default helperFn;